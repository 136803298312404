// Library imports
import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBCheckbox, MDBCol, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Component imports
import Subscription from './Subscription';
import Dropdown from '../../../components/DropDown';
import Alert from '../../../components/Alert';
// Controller imports
import { updateReciept, createPaymentIntent, subscription } from '../../../controllers/PaymentController';
// Util imports
import { calcClientEndDate, scrollToTop } from '../../../utils';
import { PACKAGES } from '../../../utils/constants';
// Stripe imports
import StripePaymentForm from '../../../components/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Action imports
import { fetchCustomer, fetchReciept } from '../../../store/slices/userSlice';
// Styled components
import { LabelValueListGroup } from '../../App/styles';
import { CardTitle } from '../../App/GameCards/GameCard/windows/styles';
import { AutoPayWrapper, Label } from '../../../static_pages/Register/Pay/styles';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ManagePackage = () => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [message, setMessage] = useState('');
    const [newPlan, setNewPlan] = useState(PACKAGES['weekly-access']);
    const [clientSecret, setClientSecret] = useState('');
    const [secretLoading, setSecretLoading] = useState(false);
    const [autoPay, setAutoPay] = useState(true);

    const lifeTimeUser = (!user.reciept?.endDate || user.reciept?.endDate === 'never') && user.reciept?.type !== 'free-account';

    const getStartDate = () => {
        if (user.reciept.items.length && user.reciept.items[user.reciept.items.length - 1].startDate) {
            return moment(user.reciept.items[user.reciept.items.length - 1].startDate).format('MM/DD/YYYY');
        } else {
            return moment(user.reciept.startDate).format('MM/DD/YYYY');
        }
    };

    const getEndDate = () => {
        if (lifeTimeUser) {
            return 'Lifetime access';
        }
        if (user.reciept?.type === 'free-account') {
            return 'Free account';
        }
        return moment(user.reciept.endDate).format('MM/DD/YYYY');
    };

    useEffect(() => scrollToTop(), [message]);

    useEffect(() => {
        async function fetchData() {
            try {
                setSecretLoading(true);
                console.log(`--- Creating payment intent ---`);
                const { client_secret } = await createPaymentIntent({
                    product: { ...newPlan },
                    customer: { ...user },
                });
                setClientSecret(client_secret);
                setMessage('');
            } catch (error) {
                console.log(`Error creating intent.\n${error}`);
                setMessage(`Error creating intent`);
            } finally {
                setSecretLoading(false);
            }
        }
        if (!lifeTimeUser) {
            fetchData();
        }
    }, [newPlan, lifeTimeUser]);

    return (
        <div>
            {message && <Alert message={message} variant={message.includes('Error') ? 'danger' : 'success'} />}
            {!user.reciept.items ? (
                <Alert message="No subscription information found." variant="info" />
            ) : (
                <MDBRow>
                    <MDBCol sm={12} md={3}>
                        <MDBCard>
                            <CardTitle>All Access Package</CardTitle>
                            <LabelValueListGroup direction="row">
                                <MDBListGroupItem>
                                    <label>Start Date:</label>
                                    <span>{getStartDate()}</span>
                                </MDBListGroupItem>
                                <MDBListGroupItem>
                                    <label>End Date:</label>
                                    <span>{getEndDate()}</span>
                                </MDBListGroupItem>
                            </LabelValueListGroup>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol sm={12} md={9} className="mt-2 mt-md-0">
                        <MDBCard>
                            <CardTitle>Manage Package(s)</CardTitle>
                            {lifeTimeUser ? (
                                <MDBCardBody>
                                    <Alert message="You have lifetime access to the Line Prophet app." variant="success" />
                                </MDBCardBody>
                            ) : user.cust?.subscriptions?.data?.length ? (
                                <MDBCardBody>
                                    {user.cust?.subscriptions.data.map((subscription, i) => {
                                        return <Subscription key={i} subscription={subscription} setCustomer={() => ''} reciept={user.reciept} />;
                                    })}
                                </MDBCardBody>
                            ) : (
                                <>
                                    <LabelValueListGroup>
                                        <MDBListGroupItem>
                                            <label>{user.reciept?.type === 'free-account' ? 'Get All Access:' : 'Extend Package:'}</label>
                                            <Dropdown
                                                variant="light"
                                                dropdownStyle={{
                                                    border: '1px solid #ddd',
                                                    borderRadius: 5,
                                                }}
                                                value={newPlan?.id}
                                                setValue={selectedKey => setNewPlan(PACKAGES[selectedKey])}
                                                menuItems={['daily-access', 'weekly-access', 'monthly-access', 'yearly-access'].map(product => product)}
                                            />
                                        </MDBListGroupItem>
                                        {user.reciept?.type !== 'free-account' && (
                                            <MDBListGroupItem>
                                                <label>Current End Date:</label>
                                                <span>{moment(user.reciept.endDate).format('MM/DD/YYYY')}</span>
                                            </MDBListGroupItem>
                                        )}
                                        <MDBListGroupItem>
                                            <label>{user.reciept?.type === 'free-account' ? 'End Date' : 'New End Date:'}</label>
                                            <span>{moment(calcClientEndDate(newPlan.id, user.reciept)).format('MM/DD/YYYY')}</span>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <label>Price:</label>
                                            <span>{`$${newPlan?.price}.00`}</span>
                                        </MDBListGroupItem>
                                    </LabelValueListGroup>
                                    <AutoPayWrapper className="mt-3 mb-3 me-2 ms-2">
                                        <Label>AutoPay:</Label>
                                        <MDBCheckbox
                                            name="checkNoLabel"
                                            id="checkboxNoLabel"
                                            onChange={() => setAutoPay(!autoPay)}
                                            checked={autoPay}
                                            disabled={newPlan.id === 'daily-access'}
                                        />
                                    </AutoPayWrapper>
                                    {/* NOTE using && here does not force the stripe form to re-render and re-fetch the client secret*/}
                                    {clientSecret && !secretLoading ? (
                                        <Elements stripe={stripeTestPromise} options={{ clientSecret: clientSecret }}>
                                            <StripePaymentForm
                                                autoPay={autoPay}
                                                handleSubmit={async id => {
                                                    // This creates the isoFormatted date - There is no BE validations for isoFormatted date on the PATCH endpoint
                                                    const endDate = calcClientEndDate(newPlan.id, user.reciept);
                                                    if (autoPay && id) {
                                                        await subscription({
                                                            paymentId: id,
                                                            product: {
                                                                ...newPlan,
                                                                price: newPlan.price,
                                                            },
                                                            email: user.email,
                                                            endDate: endDate,
                                                        });
                                                    }
                                                    await updateReciept(user.reciept._id, 'endDate', endDate);
                                                    await updateReciept(user.reciept._id, 'type', newPlan.type);
                                                    await updateReciept(user.reciept._id, 'items', {
                                                        ...newPlan,
                                                        startDate: moment().toISOString(),
                                                    });
                                                    dispatch(fetchReciept());
                                                    dispatch(fetchCustomer());
                                                    setMessage('');
                                                    setNewPlan({
                                                        ...PACKAGES['weekly-access'],
                                                    });
                                                    scrollToTop();
                                                }}
                                                setError={async message => {
                                                    setMessage(message);
                                                }}
                                            />
                                        </Elements>
                                    ) : (
                                        <p>Loading payment form...</p>
                                    )}
                                    <Alert
                                        message="By clicking purchase, you agree to Line Prophet LLC's privacy policy and terms and conditions."
                                        variant="light"
                                        style={{ textAlign: 'center' }}
                                    />
                                </>
                            )}
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            )}
        </div>
    );
};

export default ManagePackage;
