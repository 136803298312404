// Library imports
import React, { useState, useEffect } from 'react';
import { Row, Col, Nav, Alert, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCalendarDay, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import moment from 'moment';
// Controller imports
import { vipPickRecord } from '../../../../../controllers/UserController';
// Component imports
import SportsNav from '../../../../../components/SportsNav';
import BarChart from '../../../../../components/Graphs/BarChart';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
// Action imports
import { addLoader, removeLoader } from '../../../../../store/slices/appSlice';
// Styled components
import { MonthlyProfitText } from './styles';

const Insights = () => {
    // Redux imports
    const { loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [data, setData] = useState(null);
    const [monthlyProfitView, setMonthlyProfitView] = useState('daily');
    const [dailyUnitData, setDailyUnitData] = useState({});
    const [yearlyUnits, setYearlyUnits] = useState(0);
    const [calendarData, setCalendarData] = useState([]);
    const [calendarSport, setCalendarSport] = useState('all');
    const [calendarTimeframe, setCalendarTimeframe] = useState('currentYear');

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(addLoader('drawer-loading'));
                const dataRes = await vipPickRecord('all', true);
                setData(dataRes);
            } catch (error) {
                console.log(`Error getting all picks VIP stats.\n${error.message}`);
            } finally {
                dispatch(removeLoader('drawer-loading'));
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data?.picks) {
            const unitsByMonthResult = unitsByMonth();
            setCalendarData(unitsByMonthResult[0]);
            setYearlyUnits(unitsByMonthResult[1]);

            const dailyUnits = unitsByDay(data);
            setDailyUnitData(dailyUnits);
        }
    }, [data, calendarTimeframe, calendarSport]);

    const calculateUnits = picks => {
        var units = 0;
        picks.forEach(pick => {
            let juice =
                pick.pickType === 'Money Line' || String(pick.pickType).toLowerCase() === 'moneyline' ? pick.pick : pick.pickJuice ? pick.pickJuice : -110;
            if (juice === 0) return;
            if (pick.result === 'win') {
                units += juice > 0 ? Math.abs((juice / 100) * pick.units) : Math.abs((-100 / juice) * pick.units);
            }
            if (pick.result === 'loss') {
                units = units - pick.units;
            }
        });
        return units;
    };

    const unitsByMonth = () => {
        var totalUnits = 0;
        const calendarData = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(
            month => {
                const filterPicks = data.picks.filter(pick => {
                    // Remove picks that are not in current sport filter
                    if (calendarSport !== 'all' && pick.sport !== calendarSport) {
                        return false;
                    }
                    // Remove picks that are not a part of current month or year
                    if (moment(pick.gameTime).format('MMMM') === month) {
                        if (calendarTimeframe === 'all') {
                            return true;
                        } else {
                            return moment(pick.gameTime).isSame(new Date(), 'year');
                        }
                    } else {
                        return false;
                    }
                });
                const units = calculateUnits(filterPicks);
                totalUnits = totalUnits + units;
                return { x: month, y: units };
            },
        );
        return [calendarData, totalUnits];
    };

    const unitsByDay = () => {
        const uniqueDays = [
            ...new Set(
                data.picks.map(pick => {
                    const dateString = new Date(pick.gameTime).toDateString();
                    if (dateString && moment(moment(dateString).month()).isSame(moment().month())) {
                        return dateString;
                    }
                }),
            ),
        ];
        const dailyData = {};
        uniqueDays.forEach(day => {
            const filterPicks = data.picks.filter(pick => {
                // Remove picks that are not in current sport filter
                if (calendarSport !== 'all' && pick.sport !== calendarSport) {
                    return false;
                }
                // Make sure the date is this year, or from any year based on filter
                if (calendarTimeframe === 'all') {
                    return moment(moment(pick.gameTime).dayOfYear()).isSame(moment(day).dayOfYear());
                } else {
                    return moment(pick.gameTime).isSame(new Date(), 'year') && moment(moment(pick.gameTime).dayOfYear()).isSame(moment(day).dayOfYear());
                }
            });
            if (day) {
                const units = calculateUnits(filterPicks);
                dailyData[day] = units;
            }
        });
        return dailyData;
    };

    return (
        <Row>
            <Col sm={12} md={6} style={{ marginBottom: '15px' }}>
                <Card className="mt-2">
                    <Card.Header>Profit Calendar</Card.Header>
                    <Card.Body>
                        <Nav variant="tabs" className="mb-2" activeKey={calendarTimeframe}>
                            <Nav.Item>
                                <Nav.Link eventKey="currentYear" id="currentYear" onClick={e => setCalendarTimeframe(e.target.id)}>
                                    {moment().year()}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="all" id="all" onClick={e => setCalendarTimeframe(e.target.id)}>
                                    All
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <SportsNav click={setCalendarSport} activeItem={calendarSport} />
                        {!data?.picks || loaders.find(loader => loader === 'drawer-loading') ? (
                            <LoadingSpinner />
                        ) : (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MonthlyProfitText>
                                        {monthlyProfitView === 'monthly' ? (
                                            <>
                                                <span>{Number(yearlyUnits).toFixed(2)}u</span>
                                                <FontAwesomeIcon
                                                    style={{
                                                        marginLeft: '5px',
                                                        marginTop: '-4px',
                                                        color: yearlyUnits >= 0 ? 'green' : 'red',
                                                    }}
                                                    icon={yearlyUnits >= 0 ? faArrowUp : faArrowDown}
                                                />
                                            </>
                                        ) : (
                                            `${moment().format('MMMM')}`
                                        )}
                                    </MonthlyProfitText>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => setMonthlyProfitView(monthlyProfitView === 'daily' ? 'monthly' : 'daily')}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        {monthlyProfitView === 'daily' ? (
                                            <FontAwesomeIcon style={{ marginRight: 5, marginTop: -3 }} icon={faCalendarWeek} />
                                        ) : (
                                            <FontAwesomeIcon style={{ marginRight: 5, marginTop: -3 }} icon={faCalendarDay} />
                                        )}
                                        {monthlyProfitView === 'daily' ? 'Yearly' : 'Calendar'}
                                    </Button>
                                </div>
                                {monthlyProfitView === 'daily' ? (
                                    <Calendar
                                        view="month"
                                        showNavigation={false}
                                        onClickDay={() => {}}
                                        onChange={() => {}}
                                        formatDay={(locale, date) => {
                                            const dateObj = new Date(date);
                                            const units = dailyUnitData?.[dateObj.toDateString()] ? dailyUnitData[dateObj.toDateString()] : 0;
                                            return (
                                                <div
                                                    className={units === 0 ? 'alert-light' : units > 0 ? 'alert-success' : 'alert-danger'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        padding: '10px 0px',
                                                    }}
                                                >
                                                    <span className="day-number">{dateObj.getDate()}</span>
                                                    <span className="unit-total">{`${units > 0 ? `+${units.toFixed(1)}` : units.toFixed(1)}u`}</span>
                                                </div>
                                            );
                                        }}
                                    />
                                ) : (
                                    <BarChart xAxisLabel="Month" yAxisLabel="Units" data={calendarData} symbol="u" fixedAmount={1} />
                                )}
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={12} md={6} style={{ marginBottom: '15px' }}>
                <Card className="mt-2">
                    <Card.Header>Profits By Unit</Card.Header>
                    <Card.Body>
                        {!data?.picks || loaders.find(loader => loader === 'drawer-loading') ? (
                            <LoadingSpinner />
                        ) : (
                            <div>
                                <Alert className="text-center" variant="info">
                                    All units represent the risk amount
                                </Alert>
                                <BarChart
                                    xAxisLabel="Unit Size"
                                    yAxisLabel="Win %"
                                    data={[0.5, 1, 1.5, 2, 2.5, 3].map(unit => {
                                        return {
                                            x: unit,
                                            y:
                                                (data.picks.filter(pick => pick.units === unit && pick.result === 'win').length /
                                                    data.picks.filter(pick => pick.units === unit && pick.result !== 'pending').length) *
                                                100,
                                        };
                                    })}
                                    symbol="%"
                                    fixedAmount={1}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Insights;
