import styled from 'styled-components';

// Container for game time, team name, and sport info
export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 10px;
`;

// Styling for game time
export const GameTime = styled.span`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
    margin-bottom: 4px;
`;

// Styling for team name
export const TeamName = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 4px;
`;

// Styling for sport info
export const SportInfo = styled.span`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
`;

// Styling for pick value (main number)
export const PickValue = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 4px;
`;

// Styling for juice (odds)
export const Juice = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    margin-top: 0;
`;

// Styling for units
export const Units = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    text-align: right;
`;

// Background container for the middle column
export const MiddleColumnBackground = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;
