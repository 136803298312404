// Library imports
import React from 'react';
import { shape, string } from 'prop-types';
import { MDBCol, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
// Styled components
import { ItemContainer, GameTime, TeamName, SportInfo, PickValue, Juice, Units } from './styles';
// Util imports
import { formatPickJuice, formatPickValue } from '../../../../../../utils';

const PlayListItem = ({ pick, color = 'light' }) => {
    return (
        <MDBListGroupItem color={color} key={pick.pickId} className="py-3">
            <MDBRow className="align-items-center">
                {/* Left Column - Game Time and Team Info */}
                <MDBCol size={4}>
                    <ItemContainer>
                        <GameTime>{moment(pick.gameTime).format('MM/DD h:mm A')}</GameTime>
                        <TeamName>{pick.teamName}</TeamName>
                        <SportInfo>({pick.sport})</SportInfo>
                    </ItemContainer>
                </MDBCol>

                {/* Middle Column - Pick Value and Juice */}
                <MDBCol size={4} className="d-flex flex-column align-items-center text-center">
                    <PickValue>{formatPickValue(pick)}</PickValue>
                    <Juice>{formatPickJuice(pick)}</Juice>
                </MDBCol>

                {/* Right Column - Units */}
                <MDBCol size={4} className="d-flex flex-column align-items-center text-center">
                    <Units>{`${Number(pick.units).toFixed(1)}u`}</Units>
                </MDBCol>
            </MDBRow>
        </MDBListGroupItem>
    );
};

PlayListItem.propTypes = {
    pick: shape({}).isRequired,
    color: string,
};

export default PlayListItem;
