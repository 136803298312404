// Library imports
import styled from 'styled-components';
import { motion } from 'framer-motion';
// Util imports
import { MEDIUM_SCREEN_WIDTH } from '../../../utils/constants';
import { MDBContainer } from 'mdb-react-ui-kit';

export const DrawerContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 75%;
    background: ${({ theme }) => theme.colors.white};
    position: fixed;
    top: 0;
    right: 0;
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
    box-shadow: -4px 5px 7px rgba(0, 0, 0, 0.4);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        width: 96% !important;
    }
`;

export const DrawerHeader = styled.div`
    background: ${({ theme }) => theme.colors.silver};
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const DrawerTitle = styled.span`
    font-size: 1.5rem;
    font-weight: 900;
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.black};
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        font-size: 1.25rem;
    }
`;

export const DrawerContent = styled(MDBContainer)`
    padding: 1rem;
`;
