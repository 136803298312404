// Libraries imports
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
// Component imports
import SportsNav from '../../../../../components/SportsNav';
import Insights from './Insights';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import PillNav from '../../../../../components/PillNav';
import TabNav from '../../../../../components/TabNav';
import Alert from '../../../../../components/Alert';
import PlayListItem from './PlayListItem';
// Controller imports
import { vipPickRecord } from '../../../../../controllers/UserController';
// Util imports
import { sortPicks } from '../../../../../utils';
import { FUTURES } from '../../../../../utils/constants';
// CSS imports
import 'react-calendar/dist/Calendar.css';
import '../../../../../css/Calendar.css';
// Styled Components
import { LabelValueListGroup } from '../../../../App/styles';
import { DrawerContent } from '../../styles';
import { CardHeader, InsightValue, InsightLabel } from './styles';

const VIPDrawer = () => {
    // Local state
    const [data, setData] = useState({});
    const [loadingWeekly, setLoadingWeekly] = useState(false);
    const [loadingTimeFrame, setLoadingTimeFrame] = useState(true);
    const [vipTimeframe, setVIPTimeFrame] = useState('week');
    const [sport, setSport] = useState('all');
    const [tab, setTab] = useState('weekly');
    const [upcomingTab, setUpcomingTab] = useState('Main Card');

    useEffect(() => {
        async function fetchVIPData() {
            try {
                const timeFrameRes = await vipPickRecord(vipTimeframe, true);
                setData(timeFrameRes);
            } catch (error) {
                console.log(`Error getting timeframe picks VIP stats.\n${error.message}`);
            } finally {
                setLoadingTimeFrame(false);
                setLoadingWeekly(false);
            }
        }
        fetchVIPData();
    }, [vipTimeframe]);

    return (
        <DrawerContent fluid>
            <TabNav tabs={['weekly', 'insights']} activeTab={tab} setTab={setTab} containerStyle={{ marginBottom: '1rem' }} />
            {loadingTimeFrame ? (
                <LoadingSpinner />
            ) : tab === 'weekly' && data.picks ? (
                <MDBRow className="g-2">
                    <MDBCol sm={12} md={6}>
                        <MDBCard>
                            <CardHeader>Pending Plays</CardHeader>
                            <MDBCardBody>
                                <PillNav tabs={['Main Card', 'Futures']} activeTab={upcomingTab} setActiveTab={setUpcomingTab} />
                                <Alert message="All units represent the risk amount" variant="info" />
                                <MDBListGroup>
                                    {sortPicks(upcomingTab === 'Futures' ? FUTURES : data.picks, 'asc')
                                        .filter(pick => (upcomingTab === 'Futures' ? true : pick.result === 'pending'))
                                        .map(pick => {
                                            return <PlayListItem key={pick.pickId} pick={pick} />;
                                        })}
                                </MDBListGroup>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol sm={12} md={6}>
                        <MDBCard>
                            <CardHeader>Quick Insights</CardHeader>
                            <MDBCardBody>
                                <TabNav
                                    tabs={['week', 'month', 'season', 'all']}
                                    activeTab={vipTimeframe}
                                    setActiveTab={setVIPTimeFrame}
                                    containerStyle={{ marginBottom: '1rem' }}
                                />
                                <SportsNav click={setSport} activeItem={sport} />
                                {loadingWeekly ? (
                                    <LoadingSpinner />
                                ) : (
                                    <LabelValueListGroup>
                                        <MDBListGroupItem>
                                            <InsightLabel>Record:</InsightLabel>
                                            <InsightValue>{`${data.record[sport].wins}-${data.record[sport].losses}`}</InsightValue>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <InsightLabel>Units:</InsightLabel>
                                            <InsightValue>
                                                {`${Number(data.record[sport].units).toFixed(2)}u`}{' '}
                                                {data.record[sport].units >= 0 ? (
                                                    <MDBIcon color="success" icon="arrow-up" />
                                                ) : (
                                                    <MDBIcon color="danger" icon="arrow-down" />
                                                )}
                                            </InsightValue>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <InsightLabel>ROI:</InsightLabel>
                                            <InsightValue>{`${Number(data.record[sport].roi).toFixed(2)}%`}</InsightValue>
                                        </MDBListGroupItem>
                                    </LabelValueListGroup>
                                )}
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard className="mt-2">
                            <CardHeader>Weekly Results</CardHeader>
                            <MDBCardBody>
                                <Alert message="All units represent the risk amount" variant="info" />
                                <MDBListGroup>
                                    {sortPicks(data.picks)
                                        .filter(pick => {
                                            if (pick.result !== 'pending') {
                                                var curr = new Date();
                                                curr.setHours(0, 0, 0, 0);
                                                const startOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay()));
                                                return moment(pick.gameTime).isSameOrAfter(startOfWeek);
                                            } else {
                                                return false;
                                            }
                                        })
                                        .map(pick => {
                                            const color = pick.result === 'win' ? 'success' : pick.result === 'loss' ? 'danger' : 'warning';
                                            return <PlayListItem key={pick.pickId} pick={pick} color={color} />;
                                        })}
                                </MDBListGroup>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            ) : (
                <Insights />
            )}
        </DrawerContent>
    );
};

export default VIPDrawer;
