// Library imports
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
// Component imports
import GameSearchFilter from '../../../components/SearchFilters/GameSearchFilter';
import GameCard from './GameCard';
import NoData from '../../../components/NoData';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BackToTop from '../../../components/BackToTop';
import Grid from '../../../components/Layouts/Grid';
// Controller imports
import { vipPickRecord } from '../../../controllers/UserController';
// Util imports
import { filterGames } from './utils';

Array.prototype.hasMin = function (attrib) {
    return (
        (this.length &&
            this.reduce(function (prev, curr) {
                return prev[attrib] < curr[attrib] ? prev : curr;
            })) ||
        null
    );
};

const GameCards = () => {
    // Redux
    const { filters, sport, gameData, loaders } = useSelector(state => state.app);
    // Local state
    const [value, setValue] = useState('');
    const [activeDate, setActiveDate] = useState(moment().toISOString());
    const [ratingFilter, setRatingFilter] = useState(0);
    const [vipPicks, setVIPPicks] = useState([]);

    useEffect(() => {
        if (!gameData.length) {
            setActiveDate(moment().toISOString());
        } else {
            setActiveDate(gameData.hasMin('dateTime').dateTime);
        }
    }, [gameData]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await vipPickRecord('week', true);
                setVIPPicks(res.picks);
            } catch (error) {
                console.log(`Error getting VIP picks (GameCards)\n${error}`);
            }
        }
        fetchData();
    }, []);

    const processGameCards = () => {
        const filteredGames = filterGames(gameData, value, filters, activeDate);
        if (filteredGames.length) {
            const gameCards = filteredGames.map(game => {
                const isVIPPlay = vipPicks.find(pick => {
                    return (
                        moment(pick.gameTime).isSame(moment(game.dateTime), 'D') &&
                        (game.homeTeam.teamId === pick.teamId || game.awayTeam.teamId === pick.teamId) &&
                        pick.pickType !== 'prop'
                    );
                });
                return <GameCard game={game} key={game.gameId} sport={sport} vipPlay={isVIPPlay} />;
            });
            return gameCards;
        } else {
            return <NoData />;
        }
    };

    return (
        <div>
            <GameSearchFilter
                gameData={gameData}
                activeDate={activeDate} // This is the active date
                setActiveDate={setActiveDate}
                value={value}
                setValue={setValue}
                ratingFilter={ratingFilter}
                setRatingFilter={setRatingFilter}
            />
            <Grid>{loaders.find(loader => loader === 'game-data') ? <LoadingSpinner /> : processGameCards()}</Grid>
            <BackToTop />
        </div>
    );
};

export default GameCards;
