export const SMALL_SCREEN_WIDTH = '576px';
export const MEDIUM_SCREEN_WIDTH = '768px';
export const LARGE_SCREEN_WIDTH = '992px';
export const XL_SCREEN_WIDTH = '1200px';
export const ADVANCED_SEARCH_SCREEN_SIZE = 1440;
export const MOBILE_SCREEN_SIZE = 768;

export const ACTIVE_SPORTS = ['nba', 'nhl', 'cbb'];

export const SPORTS = ['nfl', 'nhl', 'nba', 'cfb', 'cbb', 'mlb', 'ncaa-baseball'];

export const PACKAGES = {
    'play-of-day': {
        name: 'Play of the Day',
        price: 5,
        id: 'play-of-day',
        desc: 'Get one play delivered to your email.  This play is guaranteed, which means if it does not hit your will get the next play free!',
        type: 'picks',
    },
    daily: {
        name: 'Daily Picks',
        price: 15,
        id: 'daily',
        desc: "Receive a daily selection of picks delivered to your email address.  These picks will be generated by Line Prophet's algorithms and selected by our experts to be delivered to your email.  This package does NOT come with your own account to access the website.",
        type: 'picks',
    },
    5050: {
        name: '50 Play Package',
        price: 25,
        id: '5050',
        desc: "Receive a selection of 50 picks delivered to your email address daily.  These picks will be generated by Line Prophet's algorithms and selected by our experts to be delivered to your email.  This package does NOT come with your own account to access the website.",
        type: 'picks',
    },
    'free-access': {
        name: 'Free Access',
        price: 0,
        id: 'free-access',
        desc: 'This will get you access to the free features Line Prophet has to offer.',
        type: 'free-account',
    },
    'daily-access': {
        name: 'Daily All Access',
        timeFrame: 'daily',
        price: 5,
        id: 'daily-access',
        planId: process.env.REACT_APP_DAILY_SUB,
        desc: 'This is the best package we have.  You will receive your own account to access the entire Line Prophet system.  You will also be requested to follow @LineProphetVIP on Twitter and join our private Discord server!',
        type: 'account',
    },
    'weekly-access': {
        name: 'Weekly All Access',
        timeFrame: 'weekly',
        price: 25,
        id: 'weekly-access',
        planId: process.env.REACT_APP_WEEKLY_SUB,
        desc: 'This is the best package we have.  You will receive your own account to access the entire Line Prophet system.  You will also be requested to follow @LineProphetVIP on Twitter and join our private Discord server!',
        type: 'account',
    },
    'monthly-access': {
        name: 'Monthly All Access',
        timeFrame: 'monthly',
        price: 49,
        id: 'monthly-access',
        planId: process.env.REACT_APP_MONTHLY_SUB,
        desc: 'This is the best package we have.  You will receive your own account to access the entire Line Prophet system.  You will also be requested to follow @LineProphetVIP on Twitter and join our private Discord server!',
        type: 'account',
    },
    'yearly-access': {
        name: 'Yearly All Access',
        timeFrame: 'yearly',
        price: 299,
        id: 'yearly-access',
        planId: process.env.REACT_APP_YEARLY_SUB,
        desc: 'This is the best package we have.  You will receive your own account to access the entire Line Prophet system.  You will also be requested to follow @LineProphetVIP on Twitter and join our private Discord server!  This package will last for a year from the date of purchase!',
        type: 'account',
    },
};

export const PICK_OBJ = {
    sport: '',
    teamName: '',
    pickTeamId: 0,
    pickValue: 1,
    pickJuice: -110,
    pickType: '',
    units: 1,
    gameTime: '',
    gameId: '0',
    analysis: '',
};

export const All_FILTERS = [
    {
        id: 'power-rank',
        label: 'Large Power Difference',
        desc: 'This filter will give you all games in which our power score shows a 5 point or greater advantage from the Vegas spread.',
    },
    {
        id: 'road-dogs',
        label: 'Road Dogs',
        desc: 'These are dogs on the road.',
    },
    {
        id: 'home-favs',
        label: 'Home Favorites',
        desc: 'This chart examines how often home favorites produce winning moneyline tickets.',
    },
    {
        id: 'road-favorites',
        label: 'Road Favorites',
        desc: 'These teams are favored despite not being at home.',
    },
    {
        id: 'division-game',
        label: 'Division Games',
        desc: 'Games in which the teams are in the same division.',
    },
    {
        id: 'conference-game',
        label: 'Conference Games',
        desc: 'Games in which the teams are in the same conference.',
    },
];

export const EDITABLE_TRENDS = [
    {
        title: 'Road Favorites',
        description: 'This chart examines how often road favorites produce winning tickets.',
        id: 'road-favorites',
    },
    {
        title: 'Road Dogs',
        description: 'This chart examines how often road dogs produce winning moneyline tickets.',
        id: 'road-dogs',
    },
    {
        title: 'Home Favorites',
        description: 'This chart examines how often home favorites produce winning moneyline tickets.',
        id: 'home-favs',
    },
    {
        title: 'Team Days of the Week',
        description: "Examine a team's results on any given day of the week.",
        id: 'team-days-of-week',
    },
];

export const TOUR_CONFIGS = {
    games: [
        {
            // TODO grid item wrapper no longer exists
            selector: '.grid-item-wrapper',
            content: `Ok, let's get started.  These are the matchup cards which have all the insights you need.`,
        },
        {
            selector: '.power-rank-wrapper',
            content: `The Powerline indicates the algorithm's lean in a game. In moneyline sports, it reflects the variance from the Vegas moneyline, while in spread sports, it signifies the variance from the Vegas spread.`,
        },
        {
            selector: '.game-card-toolbar',
            content: `Navigate through each of the windows to unlock different types of data.`,
        },
        {
            selector: '#sidebar',
            content: `The VIP Picks drawer is where all of our VIP plays are located.  You can also view VIP pick statistics and trends here.`,
        },
        {
            selector: '#header-navbar [aria-label="twitter"]',
            content: `VIP members get access to our private Twitter.  Expert plays and results will be sent here.`,
        },
        {
            selector: '#header-navbar [aria-label="discord"]',
            content: `VIP members get access to our private Discord server where we post all of our analysis.`,
        },
    ],
    trends: [
        {
            selector: '#trend-graph-container',
            content: "This graph shows the trend's performance in different situations.",
        },
        {
            selector: '#toggle-content-view-button',
            content: 'Toggle between graph view and game list view.  The game list will show the games included in the graph representation.',
        },
        {
            selector: '.form-control',
            content: 'Adjust the trend odds to see how often a trend hits in different situations.',
        },
        {
            selector: '#change-trend-tabs',
            content: 'Change the trend you are viewing.',
        },
    ],
    'search-builder': [
        {
            selector: '#search-drawer',
            content: 'Build your query to view historical game data in specific situations.',
        },
        {
            selector: '#home-away-switch',
            content: 'Quickly switch between home and away teams.',
        },
        {
            selector: '#add-filters',
            content: 'Add more filters to your query to get even better results.',
        },
    ],
};

export const FUTURES = [
    {
        teamName: 'Malik Nabers over 74.5 receptions',
        pick: -112,
        units: 1,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Jaylen Waddle over 1000.5 yards',
        pick: -113,
        units: 2,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Will Levis over 3375.5 Passing yards',
        pick: -110,
        units: 3,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Sam Darnold 4000+ passing yards',
        pick: 350,
        units: 0.4,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Sam Darnold 4500+ passing yards',
        pick: 3000,
        units: 0.1,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Caleb Williams 4000+ passing yards',
        pick: 350,
        units: 0.4,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Caleb Williams 4500+ passing yards',
        pick: 2000,
        units: 0.1,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Will Levis 4000+ passing yards',
        pick: 380,
        units: 0.8,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Will Levis 4500+ passing yards',
        pick: 2000,
        units: 0.2,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Caleb Williams to lead rookies in passing yards',
        pick: -120,
        units: 3.6,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Rashee Rice to lead the AFC West in Rec yards',
        pick: 1000,
        units: 0.5,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Tua Tagovailia to throw for 4000+ yards',
        pick: -115,
        units: 2,
        sport: 'nfl',
        pickType: 'future',
    },
    {
        teamName: 'Lions/Chiefs Division Winning Parlay',
        pick: 137,
        units: 3,
        sport: 'nfl',
        pickType: 'future',
    },
];

export const EMAIL_TEMPLATES = ['renewal', 'upgrade', 'become a member', 'football_season'];
