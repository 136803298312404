// Library imports
import React from 'react';
import { func, string } from 'prop-types';
import { MDBCol, MDBInput } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow, SearchWrapper } from './styles';
// Action imports
import { setPropCategory, setPropName } from '../../store/slices/appSlice';

const labelMap = {
    playerProps: 'Player Props',
    'Player Props': 'playerProps',
};

const PropSearchFilter = ({ value, setValue }) => {
    const theme = useTheme();
    // Redux
    const { propData, propName, propCategory, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();

    // NOTE this will through an error if still loading when generating the menu items
    if (!propData || loaders.find(loader => loader === 'prop-data')) {
        return <></>;
    }

    return (
        <SearchFilterContainer fluid>
            <SearchFilterRow className="g-0">
                <MDBCol md={12} lg={2} className="first-col">
                    <Dropdown
                        caption="Category"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                        }}
                        value={labelMap[propCategory]}
                        setValue={selectedKey => dispatch(setPropCategory(labelMap[selectedKey]))}
                        menuItems={Object.keys(propData).map(key => labelMap[key])}
                    />
                </MDBCol>
                <MDBCol md={12} lg={6} className="search-col">
                    <SearchWrapper>
                        <MDBInput
                            type="search"
                            placeholder="Start typing team or player name..."
                            aria-label="Search"
                            id="card-search"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </SearchWrapper>
                </MDBCol>
                <MDBCol md={12} lg={4} className="last-col">
                    <Dropdown
                        caption="prop type"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                        }}
                        value={
                            Object.values(propData[propCategory]).find(prop => prop.id === propName)
                                ? Object.values(propData[propCategory]).find(prop => prop.id === propName).name
                                : ''
                        }
                        setValue={selectedKey => dispatch(setPropName(Object.values(propData[propCategory]).find(prop => prop.name === selectedKey).id))}
                        menuItems={Object.keys(propData[propCategory]).map(key => {
                            const prop = propData[propCategory][key];
                            return prop.name;
                        })}
                    />
                </MDBCol>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

PropSearchFilter.propTypes = {
    value: string,
    setValue: func.isRequired,
};

export default PropSearchFilter;
